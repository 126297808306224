<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "MyParameters",

  metaInfo() {
    return {
      title: this.$t("paramSP.title"),
      titleTemplate: (titleChunk) => {
        return titleChunk
          ? `${titleChunk} - ${this.$t("meta.titleTemplate")}`
          : this.$t("meta.titleTemplate");
      },
    };
  },

  extends: View,

  mixins: [
    LoadSections(["serviceprovider/sections/serviceprovider-parameters"]),
  ],

  props: {
    id: {
      type: String,
      default: "progdpr",
    },
  },
};
</script>
